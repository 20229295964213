import React, {} from "react";
import { makeStyles } from '@material-ui/core/styles'
import { background, helperText, mainText } from '../../palette'
import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRef } from "react";

const useStyles = makeStyles({
	root:{
		background:background,
		height:'100vh',
		display:'flex',
		flexDirection:'column',
		alignItems:'center',
		justifyContent:'center'
	},
	header:{
		fontSize:42,
		color:mainText,
		letterSpacing:2
	},
	helperText:{
		fontSize:20,
		color:helperText,
		letterSpacing:2,
		marginTop:24,
		transition:'opacity 1s ease-in-out'
	},
	hiddenBlock:{
		width:100,
		height:100,
		// background:'white',
		position:'absolute',
		top:0,
	}
});

function Home() {
	const classes = useStyles();

	const [opacity, setOpacity] = useState(0)
	const hiddenClickCount = useRef(0)
	const hiddenClickTimeout = useRef()
	const [isCheat, setIsCheat] = useState(false)
	const history = useHistory()

	useEffect(()=>{
		setIsCheat(false)

		let currenrtOpacity = opacity;
		let interval = setInterval(()=>{
			if(currenrtOpacity===0){
				currenrtOpacity = 1;
			}else{
				currenrtOpacity = 0;
			}
			setOpacity(currenrtOpacity)
		},1000)
		
		return ()=>{
			clearInterval(interval)
		}
	// eslint-disable-next-line
	},[])

	const onHiddenClick = (event) => {
		event.preventDefault();
		event.stopPropagation()
		clearTimeout(hiddenClickTimeout.current)
		hiddenClickCount.current += 1;
		hiddenClickTimeout.current = setTimeout(()=>{
			hiddenClickCount.current = 0
		},1000)
		if(hiddenClickCount.current>9){
			localStorage.setItem('isDevMode',true)
			history.push('/hidden_task')
		}
	}

	const onCheatClick = event => {
		event.preventDefault();
		event.stopPropagation()
		setIsCheat(true)
	}

	const onStart = () => {
		localStorage.setItem('isChoosing',true)
		history.push(isCheat?'/chose_adventure':'/choose_adventure')
	}

	return (
		<div className={classes.root} onClick={onStart}>
			<div className={classes.hiddenBlock} style={{left:0}} onClick={onHiddenClick}>

			</div>
			<div className={classes.hiddenBlock} style={{right:0}} onClick={onCheatClick}>

			</div>
			<Typography className={classes.header}>大冒险抽签器</Typography>
			<Typography className={classes.helperText} style={{opacity}}>
				点击屏幕开始
			</Typography>
		</div>
	);
}

export default Home;