import React, { useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles'
import { background, focusText, helperText, mainText } from '../../palette'
import {  Typography, Button } from "@material-ui/core";
import { useState } from "react";
import { getHiddenTaskList, taskList } from "../../utilities";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
	root:{
		background:background,
		height:'100vh',
		display:'flex',
		flexDirection:'column',
		alignItems:'center',
		// justifyContent:'center',
		overflow:'auto'
	},
	instruction:{
		color:helperText,
		fontSize:30,
		marginBottom:120,
		marginTop:160
	},
	mainText:{
		color:focusText,
		fontSize:24,
		marginLeft:40,
		marginRight:40,
		whiteSpace:'pre-wrap',
		wordBreak:'break-word',
	},
	backButton:{
		color:mainText,
		border:`1px solid ${mainText}`,
		position:'fixed',
		bottom:0,
		marginLeft:32,
		marginBottom:24,
		padding:'6px 30px',
		fontSize:20,
		left:0
	}
});

function ChooseAdventure(props) {
	const classes = useStyles();
	const [text, setText] = useState();
	const [selected, setSelected] = useState(false)
	const interval = useRef()
	const history = useHistory()
	const isCheat = props.isCheat

	const randomPick = () => {
		let fullList = [...taskList,...getHiddenTaskList()]
		return fullList[Math.floor(fullList.length * Math.random())]
	}

	const cheatPick = () => {
		let fullList = getHiddenTaskList()
		if(fullList.length===0)fullList = taskList
		return fullList[Math.floor(fullList.length * Math.random())]
	}

	useEffect(()=>{
		if(!localStorage.getItem('isChoosing')){
			history.push('/home')
		}
		localStorage.removeItem('isChoosing')

		interval.current = setInterval(()=>{
			setText(randomPick())
		},100)
		return ()=>{
			clearInterval(interval.current)
		}
	// eslint-disable-next-line
	},[])

	const onSelect = () => {
		if(selected)return;
		clearInterval(interval.current)
		setSelected(true)
		if(isCheat){
			setText(cheatPick())
		}else{
			setText(randomPick())
		}
	}
	
	return (
		<div className={classes.root} onClick={onSelect}>
			<Typography className={classes.instruction}>
				{
					selected?
					'啊哈！'
					:
					'再次点击屏幕确定选择'
				}
			</Typography>
			<Typography className={classes.mainText}>{text}</Typography>
			<Button className={classes.backButton} onClick={()=>history.push('/home')}>
				返回
			</Button>
		</div>
	);
}

export default ChooseAdventure;