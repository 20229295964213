import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import React from "react";
import Home from './containers/Home/Home';
import HiddenTask from "./containers/HiddenTask/HiddenTask";
import ChooseAdventure from "./containers/ChooseAdventure/ChooseAdventure";


function App() {

  return (
    <Router>
      <Switch>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/choose_adventure">
          <ChooseAdventure />
        </Route>
        <Route path="/chose_adventure">
          <ChooseAdventure isCheat={true}/>
        </Route>
        <Route path="/hidden_task">
          <HiddenTask />
        </Route>
        <Redirect from="*" to="/home"/>
      </Switch>
    </Router>
  );
}

export default App;
