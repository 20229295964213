export const taskList = [
 '给你的心上人发一张尴尬的自拍',
 '不带音乐跳舞一分钟',
 '大声朗读你的最后一条短信',
 '做8个俯卧撑',
 '不用手喝一杯水',
 '单脚站立，直到下一次轮到你',
 '模仿房间里的某个人',
 '和某人交换一件衣服',
 '挠痒痒一分钟',
 '亲指定的物品',
 '对外大喊我是猪',
 '向一位异性表白3分钟',
 '背一位异性绕场一周',
 '由其他玩家指定5种动物，你来模仿叫声',
 '大声念出：“嘤嘤嘤，人家受委屈了啦，快来哄哄人家，要亲亲要抱抱要举高高。”',
 '说出数字一至十开头的成语',
 '用屁股写名字',
 '选两位异性用嘴唇传纸牌',
 '你觉得你曾经喜欢的人和在座哪个人最像?',
 '如果时间能倒流你希望回到哪一时间，为什么?',
 '你的初吻是几岁，被谁夺取的?',
 '你接受姐弟恋吗?几岁的范围可以接受?',
 '如果让你kiss现场的某一位异性，你会选择谁，为什么?',
 '从小到大最丢脸出丑的事情是什么?'
]

export const getHiddenTaskList = () => {
	let list = [] 
	if(localStorage.getItem('hidden_task')){
		list = JSON.parse(localStorage.getItem('hidden_task'));
	}
	return list;
}

export const saveHiddenTask = taskName => {
	let list = getHiddenTaskList()
	list.push(taskName)
	localStorage.setItem('hidden_task',JSON.stringify(list))
}

export const deleteHiddenTask = taskName => {
	let list = getHiddenTaskList()
	list = list.filter(l=>l!==taskName)
	localStorage.setItem('hidden_task',JSON.stringify(list))
}