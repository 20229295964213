import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles'
import { background, helperText, mainText } from '../../palette'
import { InputBase, Typography, IconButton, Button } from "@material-ui/core";
import { useState } from "react";
import { deleteHiddenTask, getHiddenTaskList, saveHiddenTask } from "../../utilities";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
	root:{
		background:background,
		height:'100vh',
		display:'flex',
		flexDirection:'column',
		alignItems:'center',
		overflow:'auto'
	},
	header:{
		fontSize:42,
		color:mainText,
		letterSpacing:2,
		marginTop:24
	},
	helperText:{
		color:helperText,
		marginTop:8,
		marginBottom:24,
		fontSize:13.75,
		whiteSpace:'pre-wrap'
	},
	inputBase:{
		border:`1px solid ${mainText}`,
		borderRadius:4,
		color:mainText,
		padding:'3px 8px',
		flexGrow:1
	},
	addButton:{
		color:mainText,
		fontSize:24,
		width:32,
		height:32,
		marginLeft:24,
		border:`1px solid ${mainText}`
	},
	listItem:{
		display:'flex',
		alignItems:'center',
		color:mainText,
		width:'80vw',
		overflow:'hidden',
		height:56,
		borderBottom:`1px solid ${helperText}`
	},
	listText:{
		flexGrow:1,
	},
	backButton:{
		color:mainText,
		border:`1px solid ${mainText}`,
		position:'fixed',
		bottom:0,
		marginLeft:32,
		marginBottom:24,
		padding:'6px 30px',
		fontSize:20,
		left:0
	}
});

function HiddenTask() {
	const classes = useStyles();
	const history = useHistory()
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	const [value, setValue] = useState('')

	useEffect(()=>{
		if(!localStorage.getItem('isDevMode')){
			history.push('/home')
		}
		localStorage.removeItem('isDevMode')
	// eslint-disable-next-line
	},[])

	return (
		<div className={classes.root}>
			<Typography className={classes.header}>设置</Typography>
			{
				<Typography className={classes.helperText}>
					输入任务后点击 "+" 添加{'\n'}
					点击 "-" 删除任务
				</Typography>
			}
			{
				getHiddenTaskList().map(el=>(
					<div className={classes.listItem}>
						<Typography className={classes.listText}>
							{el}
						</Typography>
						<IconButton 
							onClick={()=>{
								deleteHiddenTask(el);
								forceUpdate();
							}}
							className={classes.addButton}
						>
							-
						</IconButton>
					</div>
				))
			}
			<div style={{display:'flex',alignItems:'center',width:'80vw',marginBottom:120,marginTop:32}}>
				<InputBase
					className={classes.inputBase}
					placeholder="输入自定义任务"
					value={value}
					onChange={event=>setValue(event.target.value)}
				/>
				<IconButton 
					className={classes.addButton}
					onClick={()=>{
						if(value.trim()){
							saveHiddenTask(value)
							setValue('')
							forceUpdate();
						}
					}}
				>
					+
				</IconButton>
			</div>
			<Button className={classes.backButton} onClick={()=>history.push('/home')}>
				返回
			</Button>
		</div>
	);
}

export default HiddenTask;